@font-face {
  font-family: 'Poppins-Regular';
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Light';
  src: url('./assets/fonts/Poppins-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-Italic';
  src: url('./assets/fonts/Poppins-Italic.ttf') format('truetype');
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif, 'Poppins-Light';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f5f8f9 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.hide-scrollbar {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}
.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.text-align-center .MuiDataGrid-columnHeaderTitleContainerContent {
  width: 100%;
}

.text-align-center .MuiDataGrid-columnHeaderTitle {
  width: 100%;
}

.boldHeader .MuiDataGrid-columnHeaderTitle {
  font-weight: 900;
}

.MuiDataGrid-menuIcon {
  display: none !important;
}

.height-100 .MuiBottomNavigation-root {
  height: auto;
}

.position-manangement-modal .MuiBox-root {
  height: 98vh;
  max-height: 98vh;
  padding: 15px 25px;
}
.hiddenButton {
  visibility: hidden;
  position: absolute;
  z-index: -999;
  width: 550px;
}

.Toastify__toast-body > div:last-child {
  word-break: break-word;
}

/***** Tables body row ******/
/* .MuiTableBody-root .MuiTableRow-root {
  box-shadow: 0px 4px 12px rgba(112, 144, 176, 0.32);
  border-radius: 12px 12px 0px 0px;
} */

.Toastify__toast-body > div:last-child {
  word-break: break-word;
}

/* remove arrow in case of type numbers  */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
.hiddenButton {
  visibility: hidden;
  position: absolute;
  z-index: -999;
  width: 550px;
}

/* campaign changes */
.campaign-Table-Row {
	box-shadow:none !important;
}

.rejection-field .MuiOutlinedInput-input{
  padding: 0px;
}